import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/chatp/Projects/farming-season/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "benjamin-shop"
    }}>{`Benjamin Shop`}</h1>
    <hr></hr>
    <h3 {...{
      "id": "consumable-item"
    }}>{`Consumable Item`}</h3>
    <p>{`Consumable Items are consumed when being activated.`}</p>
    <p><img alt="mat" src={require("./public/images/consumable.png")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      